.App {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: 'menu map';
  grid-template-rows: 100%;
  grid-template-columns: 400px auto;
}

.menu {
  grid-area: menu;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.header {
  padding-top: 16px;
}

.tab-pane {
  text-align: initial;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabs {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-tabs-content {
  flex: 1 !important;
}

.status-high {
  color: #b71c1c;
}

.status-medium {
  color: #ff6f00;
}

.status-low {
  color: #e8c06f;
}

.ant-tabs-content {
  max-height: calc(100% - 60px);
}

.logo {
  height: 35px;
  width: 35px;
  margin-right: 8px;
  margin-top: -8px;
}
