.deck {
  width: 100%;
  height: 100%;
}

.style {
  position: absolute;
  margin: 8px;
  right: 0;
}

.layers {
  position: absolute;
  bottom: 32px;
  right: 0;
  margin: 8px;
}

.tooltip {
  color: white;
  position: absolute;
  background-color: black;
  padding: 8px;
  z-index: 1;
  pointer-events: none;
}

.layers-label {
  font-weight: bold;
  padding-bottom: 8px;
}

.loading {
  position: absolute;
  bottom: 32px;
  left: 0;
  margin: 8px;
}

.loading-label {
  color: red;
}

.refresh {
  position: absolute;
  margin: 8px;
}

.loading-topleft {
  position: absolute;
  margin: 8px;
}
