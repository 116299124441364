/*GATHER*/

.sort {
  margin-left: 16px;
  margin-right: 8px;
}

.option {
  margin-right: 8px;
}

.gather {
  flex: 1;
  display: grid;
  grid-template:
    'select' auto
    'dividerTop' auto
    'list' 1fr
    'dividerBottom' auto
    'info' auto;
  grid-template-columns: 1fr;
}

.select-gather {
  grid-area: select;
  padding-bottom: 16px;
}

.select-el {
  width: 200px;
}

.divider {
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 32px;
  margin-right: 32px;
}

.rawlist {
  grid-area: list;
}

.info-gather {
  grid-area: info;
  padding: 16px;
  max-width: 400px;
  min-height: 200px;
}

.row {
  padding-left: 16px;
  padding-right: 16px;
}

.row:hover {
  background-color: #e6e6e6;
  transition: background-color 300ms;
  cursor: pointer;
}

.rowIcon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
}

.row-label {
  font-weight: bold;
  width: 70px;
  text-align: end;
  display: inline-block;
  margin-right: 8px;
}
