/*GATHER*/

.sort {
  margin-left: 16px;
  margin-right: 8px;
}

.option {
  margin-right: 8px;
}

.respond {
  flex: 1;
  display: grid;
  grid-template:
    'fyi' auto
    'from' auto
    'dividerTop' auto
    'list' 1fr
    'dividerMid' auto
    'to' auto
    'dividerMidBottom' auto
    'cluster-list' 1fr
    'dividerBottom' auto
    'info' auto
    'calculate' auto;
  grid-template-columns: 1fr;
  max-height: 100%;
}

.from {
  grid-area: from;
  padding-bottom: 16px;
}

.from-label {
  display: inline-block;
  padding-left: 16px;
  padding-right: 12px;
  font-weight: 700;
}

.to {
  grid-area: to;
  padding-top: 16px;
  padding-bottom: 16px;
}

.to-label {
  display: inline-block;
  padding-left: 16px;
  padding-right: 12px;
  font-weight: 700;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 32px;
  margin-right: 32px;
}

.respond-rawlist-cluster {
  grid-area: cluster-list;
}

.respond-rawlist {
  grid-area: list;
  overflow: auto;
}

.organize-info {
  grid-area: info;
  display: none;
  padding: 16px;
  max-width: 400px;
  /*min-height: 200px;*/
}

.row {
  padding-left: 16px;
  padding-right: 16px;
}

.row:hover {
  background-color: #e6e6e6;
  transition: background-color 300ms;
  cursor: pointer;
}

.rowIcon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
}

.row-label {
  font-weight: bold;
  width: 70px;
  text-align: end;
  display: inline-block;
  margin-right: 8px;
}

.fyi {
  grid-area: fyi;
  padding: 0 16px 16px;
  font-weight: 500;
}

.organize-select {
  width: 200px;
}

.divider-mid {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
}

.flooded {
  color: #00d6d6;
}

.flooded-building {
  color: red;
}

.firestation {
  color: rgb(50, 50, 240);
}

.calculate {
  grid-area: calculate;
  padding: 8px;
}

.calculate-button {
  width: 100%;
  height: 50px;
  font-weight: 700;
}