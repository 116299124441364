/*GATHER*/

.sort {
  margin-left: 16px;
  margin-right: 8px;
}

.option {
  margin-right: 8px;
}

.organize {
  flex: 1;
  display: grid;
  grid-template:
    'fyi' auto
    'select' auto
    'sliders' auto
    'dividerTop' auto
    'list' 1fr
    'dividerBottom' auto
    'info' auto;
  grid-template-columns: 1fr;
}

.select {
  grid-area: select;
  padding-bottom: 8px;
}

.divider {
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 32px;
  margin-right: 32px;
}

.rawlist {
  grid-area: list;
}

.organize-info {
  grid-area: info;
  padding: 16px;
  max-width: 400px;
  /*min-height: 200px;*/
}

.row {
  padding-left: 16px;
  padding-right: 16px;
}

.row:hover {
  background-color: #e6e6e6;
  transition: background-color 300ms;
  cursor: pointer;
}

.rowIcon {
  width: 16px;
  height: 16px;
  margin-left: 16px;
}

.row-label {
  font-weight: bold;
  width: 70px;
  text-align: end;
  display: inline-block;
  margin-right: 8px;
}

.fyi {
  grid-area: fyi;
  padding: 0 16px 16px;
  font-weight: 500;
}

.organize-select {
  width: 200px;
}

.fyi-icon {
  margin-right: 8px;
}

.sliders {
  grid-area: sliders;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-right: 8px;
  padding-bottom: 16px;
}

.slider {
  flex: 1;
}
